import axios from '../libs/ajaxRequest';



/* 获取优惠券商品列表 */
const getcouponproducts = (data) => {
    return axios.request({
        url: 'lzmall/goods/getcouponproducts',
        method: 'post',
        data
    });
};

/* 获取商品详情 */
const getproductdetail = (data) => {
    return axios.request({
        url: `product/entity/${data.appKey}/${data.productId}`,
        method: 'get'
    });
};

/* 添加商品到购物车 */
const addShoppingCard = (data) => {
    return axios.request({
        url: 'basket/add',
        method: 'post',
        data
    });
};

/* 创建订单 */
const createOrder = (data) => {
    return axios.request({
        url: 'order/create',
        method: 'post',
        data
    });
};

/* 微信支付 统一下单 */
const wxOrder = (data) => {
    return axios.request({
        url: 'https://api.mch.weixin.qq.com/v3/pay/transactions/jsapi',
        method: 'post',
        data
    });
};

/* 商品运费信息 */
const freightInfo = (data) => {
    return axios.request({
        url: `product/freight/${data.productId}`,
        method: 'get'
    });
};

export {
    getcouponproducts,
    getproductdetail,
    addShoppingCard,
    createOrder,
    wxOrder,
    freightInfo
}