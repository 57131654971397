<template>
    <div id="confirmOrder">
        <van-popup v-model="confirmOrderShow" class="confirmOrderPopup" :overlay="false">
            <div class="header">
                <i class="iconfont icon-fanhui1" @click="clear()"></i>
                <h4>确认订单</h4>
            </div>
            <div  v-if="address.length > 0">
                <div class="content">
                    <div class="order" v-if="address.length>0" @click="selectOrderPopup = true">
                        <div>
                            <p>{{address[addressIndex].shipTo}} <span>{{address[addressIndex].phone}}</span></p>
                            <p>{{address[addressIndex].address}}{{address[addressIndex].addressDetail}}</p>
                        </div>
                        <div>
                            <i class="iconfont icon-xiayibu1"></i>
                        </div>
                    </div>

                    <!-- 购物车进入结算 -->
                    <div v-if="productType == 'Array'">
                        <div class="popupInfo" v-for="(item, index) in productDetail" :key="index">
                            <div>
                                <img :src="item.listImg">
                            </div>
                            <div>
                                <h4 class="name">{{item.productName}}</h4>
                                <div class="sku">
                                    <p>{{item.sku}}</p>
                                    <p>￥{{fixedPrice(item.salePrice)}}</p>
                                </div>
                                <p class="saleIntegral">良豆<span>{{item.integral}}</span></p>
                                <p class="num"><van-stepper v-model="item.quantity" button-size="20"/></p>
                                <p class="freight"><span>运费</span><span>{{cardFreight(item, index)}}</span></p>
                                <p class="subtotal"><span>小计</span><span>￥{{fixedPrice(item.salePrice * item.quantity + cardFreight(item, index))}}</span></p>
                                <p class="sub_saleIntegral">良豆{{item.integral*item.quantity}}</p>
                            </div>
                        </div>
                    </div>

                    <!-- 详情进入结算 -->
                    <div class="popupInfo" v-if="productType == 'Object'">
                        <div>
                            <img :src="(selectSku && selectSku.imgUrl) ? selectSku.imgUrl : productDetail.coverImg">
                        </div>
                        <div v-if="selectSku">
                            <h4 class="name">{{productDetail.productName}}</h4>
                            <div class="sku">
                                <p>{{selectSku.name}}</p>
                                <p>￥{{fixedPrice(selectSku.price/100)}}</p>
                            </div>
                            <p class="saleIntegral">良豆<span>{{selectSku.saleIntegral}}</span></p>
                            <p class="num"><van-stepper :value="quantity" @change="changeNum" button-size="20"/></p>
                            <p class="freight"><span>运费</span><span>{{detailFreight()}}</span></p>
                            <p class="subtotal"><span>小计</span><span>￥{{fixedPrice(selectSku.price/100 * quantity + detailFreight())}}</span></p>
                            <p class="sub_saleIntegral">良豆{{selectSku.saleIntegral * quantity}}</p>
                        </div>

                        <div v-if="productDetail.specification.length < 1">
                            <h4 class="name">{{productDetail.productName}}</h4>
                            <div class="sku">
                                <p></p>
                                <p>￥{{fixedPrice(productDetail.skus[0].salePrice)}}</p>
                            </div>
                            <p class="saleIntegral">良豆<span>{{productDetail.skus[0].saleIntegral}}</span></p>
                            <p class="num"><van-stepper :value="quantity" @change="changeNum" button-size="20"/></p>
                            <p class="freight"><span>运费</span><span>{{detailFreight()}}</span></p>
                            <p class="subtotal"><span>小计</span><span>￥{{fixedPrice(productDetail.skus[0].salePrice * quantity + detailFreight())}}</span></p>
                            <p class="sub_saleIntegral">良豆{{productDetail.skus[0].saleIntegral * quantity}}</p>
                        </div>
                    </div>

                </div>

                <template v-if="isBlack">
                    <div class="footer">
                        <div>
                            <p>当前地区暂时缺货</p>
                        </div>
                    </div>

                </template>

                <template v-else>
                    <!-- 购物车结算tabbar -->
                    <div class="footer" v-if="productType == 'Array'">
                        <div>
                            <p>合计 <span>{{price()}}</span></p>
                            <button @click="create()">立即付款</button>
                        </div>
                    </div>

                    <!-- 详情结算tabbar -->
                    <div class="footer" v-if="productType == 'Object'">
                        <div v-if="selectSku">
                            <p>合计 <span>{{`￥${fixedPrice(selectSku.price/100 *quantity + detailFreight())} + ${selectSku.saleIntegral *quantity}良豆`}}</span></p>
                            <button @click="create()">立即付款</button>
                        </div>
                        <div v-if="productDetail.specification.length < 1">
                            <p>合计 <span>{{`￥${fixedPrice(productDetail.skus[0].salePrice * quantity + detailFreight())} + ${productDetail.skus[0].saleIntegral * quantity}良豆`}}</span></p>
                            <button @click="create()">立即付款</button>
                        </div>
                    </div>
                </template>


            </div>
        </van-popup>

        <!-- 选择地址 -->
        <van-popup v-model="selectOrderPopup" class="selectOrderPopup" :overlay="false">
            <div class="header">
                <div class="navbar">
                    <button @click="selectOrderPopup = false"><i class="iconfont icon-fanhui1"></i>选择地址</button>
                </div>
            </div>
            <div class="content">
                <div class="order" v-for="(item, index) in address" :key="index" @click="selectOrder(index)">
                    <div>
                        <i class="iconfont icon-xuanzhong1" v-if="index === addressIndex"></i>
                        <i class="iconfont icon-xuanzhongyuandian" v-else></i>
                        <i @click.stop="changeAddress(2, item)" class="iconfont icon-bianji2"></i>
                    </div>
                    <div>
                        <p>{{item.shipTo}} <span>{{item.phone}}</span> <span class="defalut" v-if="item.isDefault">默认</span></p>
                        <p>{{item.address}}{{item.addressDetail}}</p>
                    </div>
                    <!-- <div v-if="index === addressIndex"> -->
                    
                </div>
            </div>
            <div class="footer">
                <button @click="changeAddress(1)">新建地址</button>
            </div>
        </van-popup>

        <!-- 新增地址 -->
        <van-popup v-model="addPopup" class="addPopup" :overlay="false">
            <div class="navbar">
                <button @click="addPopup = false"><i class="iconfont icon-fanhui1"></i>{{isAdd ? '新增地址' : '编辑地址'}}</button>
            </div>
            <div class="content">
                <van-address-edit
                :area-list="areaList"
                :show-postal="false"
                :show-delete="false"
                show-set-default
                show-search-result
                :search-result="searchResult"
                :area-columns-placeholder="['请选择', '请选择', '请选择']"
                :address-info="addressInfo"
                @save="onSave"
                @delete="onDelete"
                @change-detail="onChangeDetail"
                />
            </div>
        </van-popup>

        <van-popup v-model="h5Show" class="h5Show" :close-on-click-overlay="false">
            <div>请确认微信支付是否完成</div>
            <p @click="h5Jump(1)">已完成支付</p>
            <p @click="h5Jump(2)">取消支付</p>
        </van-popup>



    </div>
</template>

<script>
import {createOrder, freightInfo} from '../../api/product'
import {getAddress, addAddress, editAddress, getIntergal} from '../../api/user'
import {getPayInfo, h5pay} from '../../api/wxjsapi'
import areaList from '../../libs/city'
import lzCity from '../../libs/lzCity'
import lzCity2 from '../../libs/lzCity2'
import lzCity3 from '../../libs/lzCity3'
export default {
    data () {
        return {
            createData:{ 
                regionName: '',
                address: '',
                shipTo: '',
                cellPhone: '',
                freight: 0,
                shopId: '',
                cartId: [],
                products: []
            },
            address:[],
            addressIndex: 0,
            addPopup: false, //新增收货地址
            areaList,
            searchResult: [],
            addData: { //新增地址入参
                regionId: '',
                address: '',
                addressDetail: '',
                shipTo: '',
                phone: '',
                isDefault: '',
            },
            addressInfo: {},
            selectOrderPopup: false,
            wxOrderData: {
                appid: '',
                mchid: '',
                description: '',
                out_trade_no: '',
                time_expire: '',
                attach: '',
                notify_url: '',
                amount: {
                    total: 100,
                    currency: 'CNY'
                },
                payer: {
                    openid: ''
                },
            },
            productType: '',
            currentFregiht: 0,
            fregiht: '',
            h5Show: false,
            deductionOfPoints: 0, //要扣除的积分
            isAdd: true, //编辑新增状态
            integral: '', 
            isBlack: false, //是否为地区限制
            isDraw: true, //防止重复点击
        }
    },
    props: ['confirmOrderShow', 'selectSku', 'quantity', 'productDetail'],
    mounted () {
        // console.log(this.$route);
        this.userIntergal();
    },
    watch: {
        confirmOrderShow (v, i) {
            if (v) {
                const {productDetail} = this;
                this.getAddress();
                if (productDetail instanceof Array) {
                    this.createData.cartId = [];
                    productDetail.map((data, index) => {
                        console.log(index);
                        this.getFregihtInfo(data.productId, index);
                    })
                } else {
                    this.getFregihtInfo(productDetail.productId);
                }
            }
        },

        productDetail (v, i) {
            console.log(v);
            if (v instanceof Array) {
                this.productType = 'Array';
            } else if (v instanceof Object) {
                this.productType = 'Object';
            }
        }
    },
    computed: {
        price () {
            return () => {
                const {productType, productDetail} = this;
                if (productType == 'Array') {
                    let prices = 0;
                    let integrals = 0;
                    productDetail.map(data => { 
                        prices += data.salePrice * data.quantity + data.currentFreight;
                        integrals += data.integral * data.quantity;
                    });
                    return `${prices.toFixed(2)} + ${integrals}良豆`
                }
            }
        },

        /* 购物车详情进入时运费计算 */
        cardFreight () {
            return ({freight, freightTemplateId, uniFreight}, index) => {
                const {address, addressIndex} = this;
                var money = 0;

                if (freightTemplateId > 0) {
                    let region = address[addressIndex].region;
                    freight.detail.map(data => {
                        var arr = data.address.split('，');
                        arr.splice(arr.length-1, 1);
                        for (var i=0; i<arr.length; i++) {
                            var index = region.indexOf(arr[i]);
                            if (index > -1) {
                                money = data.fee;
                                return;
                            }
                        }
                        money = freight.defaultFee;
                    });
                } else {
                    money = uniFreight;
                }
                console.log(money);
                money === -1 && (money = 0);
                this.$set(this.productDetail[index], 'currentFreight', money);
                return money;
                
            }
        },

        /* 产品详情进入时运费计算 */
        detailFreight () {
            return () => {
                const {address, addressIndex, productDetail} = this;
                const {freight, uniFreight, freightTemplateId} = productDetail;
                var test = 0;
                console.log(freight)
                console.log(freightTemplateId)
                if (freightTemplateId > 0) {
                    let region = address[addressIndex].region;
                    if (freight.detail.length > 0) {
                        freight.detail.map(data => {
                            var arr = data.address.split('，');
                            arr.splice(arr.length-1, 1);
                            for (var i=0; i<arr.length; i++) {
                                var index = region.indexOf(arr[i]);
                                if (index > -1) {
                                    test = data.fee;
                                    return;
                                }
                            }
                        });
                    } else {
                        test = freight.defaultFee;
                    }
                } else {
                    test = uniFreight;
                }
                console.log(test);
                test === -1 && (test = 0);
                this.$set(this.productDetail, 'currentFreight', test);
                return test;
            }
        },
        fixedPrice () {
            return (price) => {
                return price.toFixed(2);
            }
        }
    },
    methods: {
        clear () {
            this.$emit('update:popupShow', false);
        },

        /* 获取用户积分 */
        async userIntergal () {
            const {uid} = this.$route.query;
            let arr = {uid};
            let res = await getIntergal(arr);
            this.integral = res;
        },

        /* 获取运费信息 */
        async getFregihtInfo (productId, index) {
            let res = await freightInfo({productId});
            const {code, data, msg} = res;
            if (code === 1) {
                if (index > -1) {
                    this.$set(this.productDetail[index], 'freight', data.freight);
                    this.$set(this.productDetail[index], 'uniFreight', data.uniFreight);
                    this.$set(this.productDetail[index], 'freightTemplateId', data.freightTemplateId);
                } else {
                    this.fregiht = data;
                    this.$set(this.productDetail, 'freight', data.freight);
                    this.$set(this.productDetail, 'uniFreight', data.uniFreight);
                    this.$set(this.productDetail, 'freightTemplateId', data.freightTemplateId);
                }
               /*  this.productDetail.freight = data.freight;
                this.productDetail.uniFregiht = data.uniFreight; */
            } else {
                this.$toast(msg);
            }
        },

        /* 获取收货地址 */
        async getAddress () {
            let res = await getAddress();
            const {code, data, msg} = res;
            if (code === 1) {
                if (data.length > 0) {
                    data.map((res, index) => {
                        res.isDefault && (this.addressIndex = index);
                    })
                    const {addressIndex} = this;
                    this.address = data;
                    this.createData.regionName = data[addressIndex].address;
                    this.createData.address = data[addressIndex].addressDetail;
                    this.createData.shipTo = data[addressIndex].shipTo;
                    this.createData.cellPhone = data[addressIndex].phone;
                    // console.log(addressIndex);
                    // console.log(this.createData);
                    this.regionalRestrictions(); 
                    
                } else {
                    this.$dialog.confirm({
                        title: '请先设置收货地址',
                        message: '您还没有设置收货地址，请先设置收货地址',
                    })
                    .then(() => {
                        this.addPopup = true;
                    })
                    .catch(() => {
                        this.$emit('update:popupShow', false);
                    });
                }
            }
        },

        /* 区域限制 是否为黑白名单 */
        regionalRestrictions () {
            const {addressIndex, address, productDetail, productType} = this;
            let city = address[addressIndex].region.split(',')[1];
            if (productType == 'Object') {
                this.isBlack = productDetail.blackList.includes(lzCity3[city])
            } else if (productType == 'Array') {
                let nameList = '';
                productDetail.map(data => {
                    data.blackList && (nameList += data.blackList);
                });
                // console.log(nameList.includes(lzCity3[city]));
                this.isBlack = nameList.includes(lzCity3[city]);
            }
        },

        /* 创建订单 */
        async create () {
            const {productType, productDetail, integral, isDraw} = this;
            if (isDraw) {
                this.isDraw = false;
                // const {integral} = $route.query;
                this.createData.products = [];
                // this.createData.shopId = productDetail.shopId;
                if (productType == 'Array') { //购物车进入
                    let map = new Map();
                    var productIntegral = 0;
                    var freight = 0;
                    productDetail.map(data => {
                        if (map.has(data.shopId)) {
                            map.set(data.shopId, [...map.get(data.shopId), data]);
                        } else {
                            map.set(data.shopId, [data]);
                        }
                    });
                    map.forEach((item, key) => {
                        item.map(data => {
                            var arr = {};
                            arr.id = data.productId;
                            arr.name = data.productName;
                            arr.killFlag = false;
                            arr.sku = data.skuId;
                            arr.price = data.salePrice * data.quantity;
                            arr.price = data.salePrice * data.quantity + data.currentFreight;
                            arr.integral = data.integral * data.quantity;
                            arr.integralFrom = 1; 
                            arr.quantity = data.quantity;
                            productIntegral += data.integral * data.quantity;
                            freight += data.currentFreight;
                            this.createData.cartId.push(data.productId);
                            this.createData.products.push(arr);
                        });
                        this.createData.freight = freight;
                        this.createData.shopId = key;
                        this.deductionOfPoints = productIntegral;
                        
                        if (integral < productIntegral) {
                            this.$toast('积分不足，无法购买此商品');
                            this.isDraw = true;
                            return;
                        }
                        this.cardCrate();
                    });
                    // const {appKey, credits, timestamp, sign, uid, code, state, integral} = this.$route.query;
                    // this.$router.replace({name: 'user', query:{appKey, credits, timestamp, sign, uid, code, state, orderState:1, integral}});
                
                } else if (productType == 'Object') { //产品详情进入
                    const {productDetail, quantity} = this;
                    this.createData.shopId = productDetail.shopId;
                    if (productDetail.specification.length > 0) {  //有规格属性
                        const {selectSku} = this;
                        let arr = {};
                        arr.id = productDetail.productId; 
                        arr.name = productDetail.productName; 
                        arr.killFlag = productDetail.killFlag; 
                        arr.sku = selectSku.id; 
                        arr.price = selectSku.price/100 * quantity; 
                        arr.price = selectSku.price/100 * quantity + this.productDetail.currentFreight; 
                        arr.integral = selectSku.saleIntegral * quantity; 
                        this.deductionOfPoints = arr.integral; 
                        arr.integralFrom = 1; 
                        arr.quantity = quantity;
                        this.createData.products.push(arr);
                    } else { //无规格属性
                        const {skus} = productDetail;
                        let arr = {};
                        arr.id = productDetail.productId; 
                        arr.name = productDetail.productName; 
                        arr.killFlag = productDetail.killFlag; 
                        arr.sku = skus[0].sku;
                        arr.price = skus[0].salePrice * quantity; 
                        arr.price = skus[0].salePrice * quantity + this.productDetail.currentFreight; 
                        arr.integral = skus[0].saleIntegral * quantity;
                        this.deductionOfPoints = arr.integral; 
                        arr.integralFrom = 1; 
                        arr.quantity = quantity;
                        this.createData.products.push(arr);
                    }
                    let productIntegral = this.createData.products[0].integral;
                    this.createData.freight = this.productDetail.currentFreight;
                    if (integral < productIntegral) {
                        this.$toast('积分不足，无法购买此商品');
                        this.isDraw = true;
                        return;
                    }
                    console.log(this.createData);
                    let res = await createOrder(this.createData);
                    const {code, data, msg} = res;
                    this.$toast.clear();
                    if (code == 1) {
                        console.log(res);
                        // this.$toast('订单创建成功');
                        let us = window.navigator.userAgent;
                        if (us.indexOf('MicroMessenger') > -1) { //微信浏览器 JSAPI支付
                            this.payInfo(data);
                        } else { //非微信浏览器 h5支付
                            // this.$toast('h5支付')
                            this.h5Payment(data);
                        }
                    } else {
                        this.$toast(msg);
                    }
                }
            }
        },

        /* 购物车创建订单 */
        async cardCrate () {
            let res = await createOrder(this.createData);
            console.log(res);
            const {code, data, msg} = res;
            this.$toast.clear();
            if (code == 1) {
                this.createData.cartId = [];
                this.createData.products = [];
                const {deductionOfPoints, $route} = this;
                const {appKey, credits, timestamp, sign, uid, code, state} = $route.query;
                let inte = localStorage.getItem('integral') - deductionOfPoints;
                localStorage.setItem('integral', inte);
                this.$router.replace({name: 'user', query:{appKey, credits, timestamp, sign, uid, code, state, orderState:1, integral:inte}});
            } else {
                this.$toast(msg);
            }
        },

        /* 获取支付信息 */
        async payInfo (orderId) {
            // this.$toast(`获取支付信息：${orderId}`);
            let openid = localStorage.getItem('openid');
            if (!openid) {
                
            }
            let data = {
                openId: localStorage.getItem('openid'),
                orderId
            }
            let res = await getPayInfo(data);
            console.log(res);
            this.onBridgeReady(res.data);
        },

        /* h5Payment */
        async h5Payment (orderId) {
            let res = await h5pay({orderId});
            const {code, data, msg} = res;
            if (code === 1) {
                window.location.href = data;
                this.h5Show = true;
            }
        },

        h5Jump (status) {
            const {deductionOfPoints, $route} = this;
            var {appKey, credits, timestamp, sign, uid, code, state} = $route.query;
            if (status == 1) {
                this.$router.replace({name: 'user', query:{appKey, credits, timestamp, sign, uid, code, state, orderState:2}});
            } else {
                this.$router.replace({name: 'user', query:{appKey, credits, timestamp, sign, uid, code, state, orderState:1}});
            }
            this.h5Show = false;
        },

        onBridgeReady(res) {
            let _this = this;
            // const {appId, timeStamp, nonceStr, package, signType, paySign} = res;
            WeixinJSBridge.invoke('getBrandWCPayRequest', {
                appId: res.appId,
                //公众号名称，由商户传入     
                timeStamp: res.timeStamp,
                //时间戳，自1970年以来的秒数     
                nonceStr: res.nonceStr,
                //随机串     
                package:  res.package,
                signType:  res.signType,
                //微信签名方式：     
                paySign: res.paySign, //微信签名 
            },
            function(res) {
                const {deductionOfPoints, $route} = _this;
                var {appKey, credits, timestamp, sign, uid, code, state} = $route.query;
                // alert(integral);
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    _this.$router.replace({name: 'user', query:{appKey, credits, timestamp, sign, uid, code, state, orderState:2}});
                } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
                    _this.$router.replace({name: 'user', query:{appKey, credits, timestamp, sign, uid, code, state, orderState:1}});
                } else {
                    _this.$router.replace({name: 'user', query:{appKey, credits, timestamp, sign, uid, code, state, orderState:1}});
                }
            });
        },

        /* 新建地址 */
        async onSave(content) {
            console.log(content);
            const {addressDetail, areaCode, city, country, county, isDefault, name, postalCode, province, tel, id} = content;
            // console.log(lzCity[`${areaCode}`]);
            // this.addData.regionId = lzCity[`${areaCode}`];
            this.addData.regionId = lzCity[`${lzCity2[`${city}${county}`]}`];
            this.addData.region = `${province},${city},${county}`;
            this.addData.postCode = areaCode;
            this.addData.address = city+county;
            this.addData.addressDetail = addressDetail;
            this.addData.shipTo = name;
            this.addData.phone = tel;
            this.addData.isDefault = isDefault;
            if (this.isAdd) {
                let res = await addAddress(this.addData);
                console.log(res);
                const {code, data, msg} = res;
                if (code === 1) {
                    // this.$toast('创建成功');
                    this.addPopup = false;
                    this.getAddress();
                }
            } else {
                this.addData.id = id;
                let res = await editAddress(this.addData);
                const {code, data, msg} = res;
                if (code == 1) {
                    this.$toast('修改成功');
                    this.addPopup = false;
                    this.getAddress();
                }
                delete this.addData.id;
            }
        },

        onDelete() {
            console.log('delete');
        },

        onChangeDetail(val) {
           console.log(val);
        },

        /* 选择地址 */
        selectOrder (index) {
            this.addressIndex = index;
            const {address} = this;
            this.createData.regionName = address[index].address;
            this.createData.address = address[index].addressDetail;
            this.createData.shipTo = address[index].shipTo;
            this.createData.cellPhone = address[index].phone;
            this.selectOrderPopup = false;
        },

        /* 加载 */
        loading () {
            this.$toast.loading({
                duration: 0,
                forbidClick: true
            })
        },

        /*  */
        changeNum (res) {
            console.log(res);
            this.$emit('update:changeQuantity', res);
        },

        /* 新建编辑地址 */
        changeAddress (status, item) { //1新建 2编辑
            if (status == 1) {
                this.addressInfo = {};
                this.addPopup = true;
                this.isAdd = true;
            } else {
                console.log(item)
                this.$set(this.addressInfo, 'id', item.id);
                this.$set(this.addressInfo, 'name', item.shipTo);
                this.$set(this.addressInfo, 'tel', item.phone);
                this.$set(this.addressInfo, 'addressDetail', item.addressDetail);
                this.$set(this.addressInfo, 'areaCode', item.postCode);
                this.$set(this.addressInfo, 'isDefault', item.isDefault);
                this.addPopup = true;
                this.isAdd = false;
            }
        }
    }
}
</script>

<style lang="less" src="@/style/component/confirmOrder.less">

</style>